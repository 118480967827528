import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';
import withTranslate from '../../../common/hoc/with-translate';
import LinkList from '../../../link-list';
import LinkText from '../../../common/components/link-text';
import {
  getShowPostCount,
  getArchiveLinks,
  getUseMobileDesingSettings,
  getUseMobileLayoutSettings,
} from '../../selectors/archive-selectors';

const Archive = ({ t, archiveLinks, showPostCount, useMobileDesignSettings, useMobileLayoutSettings }) => (
  <nav aria-label={t('aria-label.archive')}>
    <LinkList
      links={archiveLinks.map((archiveLink) => {
        const monthAndYear = t('archive-link.month-and-year-utc', { time: archiveLink.periodStart });
        const a11yText =
          archiveLink.postCount === 1
            ? t('archive-link.a11y-post-count', { count: archiveLink.postCount })
            : t('archive-link.a11y-post-count-plural', { count: archiveLink.postCount });
        const postCount = showPostCount ? t('archive-link.post-count', { count: archiveLink.postCount }) : null;

        return {
          key: archiveLink.key,
          path: archiveLink.path,
          text: <LinkText text={monthAndYear} postCount={postCount} a11yText={a11yText} />,
        };
      })}
      emptyState={{ text: t('empty-state.no-posts') }}
      useMobileDesignSettings={useMobileDesignSettings}
      useMobileLayoutSettings={useMobileLayoutSettings}
    />
  </nav>
);

Archive.propTypes = {
  t: PropTypes.func.isRequired,
  archiveLinks: PropTypes.array.isRequired,
  showPostCount: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state) => ({
  archiveLinks: getArchiveLinks(state),
  showPostCount: getShowPostCount(state),
  useMobileDesignSettings: getUseMobileDesingSettings(state),
  useMobileLayoutSettings: getUseMobileLayoutSettings(state),
});

export default flowRight(connect(mapRuntimeToProps), withTranslate)(Archive);
